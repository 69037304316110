import React from 'react'
import styled, { withTheme } from 'styled-components'
import Image from 'components/Image'

const Container = styled.div`
  position: relative;
  ${({ squared }) => (squared ? 'height: 0; padding-bottom: 100%;' : '')}
  ${({ imageBorders, isEdit }) =>
    imageBorders
      ? `
        & .gatsby-image-wrapper {
          overflow: visible !important;
        }
        & img {
          padding: 16px;
          background: white;
          box-shadow: 0px 0px 10px #00000017;
          max-width: 100%;
          max-height: 100%;
          width: fit-content !important;
          height: fit-content !important;
          ${
            isEdit
              ? ''
              : `top: 50% !important;
                 left: 50% !important;
                 transform: translate(-50%, -50%);
                `
          }
        }`
      : ''}
`

function StoryblokImage({ isEdit, data, theme, ...props }) {
  return (
    <Container
      squared={data.squared}
      imageBorders={data.imageBorders}
      isEdit={isEdit}
    >
      <Image
        style={{
          backgroundColor: theme.colors[data.backgroundColor]?.color,
          ...(data.squared && { height: 0, paddingBottom: '100%' }),
        }}
        imageStyle={{ padding: data.padding ? '5px' : 0 }}
        absolute={data.squared}
        src={data.image}
        alt={data.alt}
        title={data.title}
        description={data.description}
      />
    </Container>
  )
}

export default withTheme(StoryblokImage)
