import styled from 'styled-components'

export default styled.button`
  display: block;
  border: 1px solid transparent;
  color: ${props => props.theme.colors.contrast};
  background-color: ${props => props.theme.colors.blue.color};
  width: fit-content;
  cursor: pointer;
  padding: 17px 32px;
  line-height: 1em;
  && {
    font-family: dinPro, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600;
  }
  :hover {
    filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px);
  }
  :disabled,
  [disabled] {
    background-color: ${({ theme }) => theme.colors.gray.color};
  }
`
