import React, {
  useCallback,
  useState,
  useEffect,
  Fragment,
  useMemo,
} from 'react'
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import Close from '@material-ui/icons/Close'
import { mediaQuery } from 'utils/style'
import ProductHighlightModal from './ProductHighlightModal'
import ProductAccessoryModal from './ProductAccessoryModal'
import ProductPropertyModal from './ProductPropertyModal'
import ProductModal from './ProductModal'
import CustomModal from './CustomModal'
import { Fade, Slide, Portal } from '@material-ui/core'

const StyledModal = styled(Modal)`
  overflow-x: hidden;
  overflow-y: auto;
  inset: ${({ isRedesign }) =>
    isRedesign ? '0 0 0 min(10vw, 150px)' : 0} !important;
`

const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  inset: 0;
  z-index: ${({ isRedesign }) => (isRedesign ? 1250 : -10)};
`

const Wrapper = styled.div`
  h2 {
    font-size: 50px;
    color: ${({ theme }) => theme.colors.highlight};
    font-weight: bold;
  }
  h3 {
    font-size: 30px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.highlight};
  }
  h4 {
    font-size: 25px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.highlight};
  }
  h5 {
    font-size: 25px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.color};
  }
  h6 {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.color};
  }
  border: 1px solid black;
  background-color: white;
  border-radius: 0;
  height: fit-content;
  display: flex;
  justify-content: center;
  z-index: 1000;
  inset: 0;
  min-height: ${({ isRedesign }) => (isRedesign ? '100%' : 'initial')};
  position: ${({ isRedesign }) => (isRedesign ? 'absolute' : 'unset')};
  padding: ${({ isRedesign }) => (isRedesign ? '100px 100px 50px' : '16px')};
  margin: ${({ isRedesign }) => (isRedesign ? 0 : '40px')};
  @media (max-width: 480px) {
    margin: 0;
    padding: 16px;
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 16px;
  ${mediaQuery(true)}
`

const CloseButton = styled(Close)`
  && {
    font-size: ${({ isRedesign }) => (isRedesign ? '80px' : '32px')};
    right: ${({ isRedesign }) => (isRedesign ? '50px' : '56px')};
    top: ${({ isRedesign }) => (isRedesign ? '50px' : '56px')};
  }
  cursor: pointer;
  position: fixed;
  z-index: 1500;
  ${mediaQuery()({
    media: device => (device === 'mobile' ? 'top: 16px; right: 16px;' : ''),
  })}
`

const stopPropagation = e => e.stopPropagation()

export const ModalContext = React.createContext()

export default function ModalProvider({
  open,
  onRequestClose,
  product,
  slug,
  type,
  props,
  children,
}) {
  const [modal, setModal] = useState({})

  const loadProductModal = useCallback((_product, _type, _props, _open) => {
    setModal({
      open: _open,
      props: { product: _product, ..._props },
      C:
        {
          highlight: ProductHighlightModal,
          accessory: ProductAccessoryModal,
          property: ProductPropertyModal,
        }[_type] || ProductModal,
    })
  }, [])

  const loadModal = useCallback(async (_slug, _type, _props, _open) => {
    try {
      const content = await import(`../../../.temp/${_slug}.json`)
      const prefetchData = await _props?.prefetch?.()

      if (content?.default?.component?.__typename === 'StoryblokProduct') {
        loadProductModal(content.default, _type, _props, _open)
      } else {
        setModal({
          open: _open,
          props: {
            ..._props,
            ...prefetchData,
            formData: { ..._props?.formData, ...prefetchData?.formData },
            data: content.default,
          },
          C: CustomModal,
        })
      }
    } catch (e) {
      console.log(_slug, e)
    }
  }, [])

  useEffect(() => {
    if (product) {
      loadProductModal(product, type, props, open)
    } else if (slug) {
      loadModal(slug, type, props, open)
    } else if (children) {
      setModal({ open, C: Fragment })
    }
  }, [product, slug, type, props, open])

  // modal designs and animations
  const { isRedesign, Transition, TransitionProps } = useMemo(() => {
    const isRedesign = modal.props?.data?.component?.design === 'redesign'
    const Transition = isRedesign ? Slide : Fade
    const TransitionProps = isRedesign
      ? {
          direction: 'left',
          timeout: {
            enter: 500,
            exit: 500,
          },
        }
      : {}
    return {
      isRedesign,
      Transition,
      TransitionProps,
    }
  }, [modal.props])

  return (
    <ModalContext.Provider value={{ ...modal.props, onRequestClose }}>
      {isRedesign && (
        <Portal>
          <Fade
            in={modal.open}
            timeout={{
              enter: 500,
              exit: 500,
            }}
          >
            <Backdrop onClick={onRequestClose} isRedesign={isRedesign} />
          </Fade>
        </Portal>
      )}
      <Transition in={modal.open} {...TransitionProps}>
        <StyledModal
          open={modal.open}
          hideBackdrop
          keepMounted
          onClose={onRequestClose}
          isRedesign={isRedesign}
        >
          <Wrapper onClick={stopPropagation} isRedesign={isRedesign}>
            {!isRedesign && (
              <Backdrop onClick={onRequestClose} isRedesign={isRedesign} />
            )}
            <Container>
              <CloseButton onClick={onRequestClose} isRedesign={isRedesign} />
              {children}
              {modal.C && <modal.C {...modal.props} />}
            </Container>
          </Wrapper>
        </StyledModal>
      </Transition>
    </ModalContext.Provider>
  )
}
