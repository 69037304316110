import { createGlobalStyle } from 'styled-components'
import light from 'font/DINPro-Light.otf'
import lightEot from 'font/DINPro-Light.eot'
import bold from 'font/DINPro-Bold.otf'
import boldEot from 'font/DINPro-Bold.eot'
import { mediaQuery } from 'utils/style'
import { baseColors } from './theme'

export default createGlobalStyle`
  @font-face {
    font-family: dinPro;
    src: url(${light}) format("opentype"), url(${lightEot}) format("embedded-opentype");
  }
  @font-face {
    font-family: dinPro;
    src: url(${bold}) format("opentype"), url(${boldEot}) format("embedded-opentype");
    font-weight: bold;
  }
  * {
    box-sizing: border-box;
    text-overflow: ellipsis;
    line-height: 1.5em;
  }
  .remark-table {
    tr {
      ${mediaQuery()({
    media: device =>
      (device === 'mobile'
        ? 'float: left; width: 100%; margin-bottom: 4em;'
        : '')
  })}
    }
    thead {
      ${mediaQuery()({
    media: device => (device === 'mobile' ? 'display:none;' : '')
  })}
    }
    td {
      ${mediaQuery()({
    media: device =>
      (device === 'mobile'
        ? `display: flex; float: left; width: 100%; padding: 1em;
        :before {
          content: attr(data-label);
          word-wrap: break-word;
          float: left;
          padding: 1em;
          font-weight: bold;
          margin: -1em 1em -1em -1em;
        }`
        : '')
  })}
    }
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  /* modals and portals */
  div[role="presentation"] * {
    font-family: dinPro,  "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
  }
  body {
    font-family: dinPro,  "Roboto", "Helvetica", "Arial", sans-serif;
    background-color: ${baseColors.white};
    margin: 0;
    color: ${baseColors.black};
    overflow-x: hidden;
  }
  h1 {
    font-size: 50px;
    color: ${({ theme }) => theme.colors.highlight};
    font-weight: bold;
  }
  h2 {
    font-size: 30px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.highlight};
  }
  h3 {
    font-size: 25px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.highlight};
  }
  h4 {
    font-size: 25px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.color};
  }
  h5 {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.color};
  }
  h6 {
    font-size: 20px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.color};
  }
  blockquote {
    margin: 0;
    p {
      font-size: 30px;
    }
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    alignSelf: left;
    color: ${({ theme }) => theme.colors.highlight};
  }
  .roofline {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.color}
  }
  .subline {
    marin-bottom: 32px;
    font-size: 20px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.color}
  }
  p {
    font-weight: normal;
    font-size: 16px;
    margin: 0;
    word-break: break-word;
    > strong {
      font-weight: bold;
      font-size: 20px;
    }
  }
  button {
    font-weight: light;
    font-size: 16px;
    min-height: 1.5em;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 10px 0;
    line-height: 1em;
  }
  :focus {
    outline: none;
  }

  .flat-field {
    box-shadow: none !important;
    transform: translate(-16px, 16px) !important;
    border: 1px solid #eaeaea;
    background: inherit;
  }

  @keyframes ripple {
    0%, 5%, 13% {
      transform: scale(0);
      opacity: 1;
    }
    6%, 14% {
      transform: scale(1.5);
      opacity: 0.8;
    }
    10%, 18% {
      opacity: 0;
      transform: scale(4);
    }
    11%, 19% {
      opacity: 0;
      transform: scale(0);
    }
  }

`
